import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { SadSogui } from "../Assets/Icons";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="py-32">
      <div className="flex flex-col justify-between text-center space-y-24">
        <div className="flex justify-center">
          <SadSogui />
        </div>

        <div className="space-y-8">
          <div>{t("notFound")}</div>
          <div>{t("itemSwipe.exhaustedSearchSuggestion")}</div>
        </div>

        <div>
          <Link
            to="../cravings"
            className="rounded-lg block bg-brand-500 flex justify-center text-white space-x-8 p-8 "
          >
            {t("buttons.backHome")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
