import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import LikedItemsSuggestions from "./LikedItemsSuggestions";

const ExhaustedSearch = ({ likedItems }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex m-auto">
        <div className="space-y-32 m-auto text-center justify-center items-center">
          <div>
            <h3 className="text-lg">{t("itemSwipe.exhaustedSearchTitle")}</h3>
          </div>
          {likedItems.length > 1 && (
            <LikedItemsSuggestions likedItems={likedItems} />
          )}
          <div className="space-y-12">
            <div>{t("itemSwipe.exhaustedSearchSuggestion")}</div>
            <Link
              to="../cravings"
              className="rounded-lg block bg-brand-500 flex justify-center text-white space-x-8 p-8"
            >
              {t("itemSwipe.trySomethingNew")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhaustedSearch;
