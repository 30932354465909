import React, { useState, useEffect } from "react";
import { ResourceStates } from "./ResourceStates";

// Taking an async function
// const SomeOtherComponent = () => {
//   const [data, setData] = useState()
//   const loadingState = useLoadingState(fetchSomeStuff, [])
//   return (
//     <LoadingPage loadingState={loadingState}>
//       <div> This is a page </div>
//     </LoadingPage>
//   );
// };
//
// Taking an anonymous async function
// const SomeOtherComponent = () => {
//   const [data, setData] = useState()
//   const loadingState = useLoadingState(async () => {
//     const data = await fetchSomeStuff();
//     await otherFetch();
//     setData(data)
//   }, []);
//   return (
//     <LoadingPage loadingState={loadingState}>
//       <div> This is a page </div>
//     </LoadingPage>
//   );
// };

export const useLoadingState = (onLoad, condition) => {
  const [loadingState, setLoadingState] = useState(ResourceStates.unloaded);
  const trackLoading = async () => {
    try {
      setLoadingState(ResourceStates.loading);
      await onLoad();
      setLoadingState(ResourceStates.loaded);
    } catch (error) {
      console.log(error);
      setLoadingState(ResourceStates.error);
    }
  };
  useEffect(() => {
    trackLoading();
  }, condition);
  return loadingState;
};

export const WithLoadingComponent = (
  LoadingComponent,
  ErrorLoadingComponent
) => {
  const LoadingState = ({
    result,
    children,
    render,
    loadingState,
    className,
    ...props
  }) => {
    if (loadingState === ResourceStates.error) {
      return <ErrorLoadingComponent className={className} {...props} />;
    }
    if (
      loadingState === ResourceStates.loading ||
      loadingState === ResourceStates.unloaded
    ) {
      return <LoadingComponent className={className} {...props} />;
    }
    return children || render();
  };
  return LoadingState;
};
