import React, { useState } from "react";
import { useParams } from "@reach/router";
import ItemCard from "./ItemCard";
import Loading from "../Loading";
import InfoModal from "./InfoModal";
import Modal from "../../Atoms/Modal";
import { fetchMarketingActivation } from "../ActivationRequests";
import { useLoadingState } from "../../Atoms/Loading";
import { ResourceStates } from "../../Atoms/Loading/ResourceStates";

const ItemPreview = () => {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState({});

  const loadingSessionState = useLoadingState(async () => {
    const marketing_activation = await fetchMarketingActivation(params.uuid);
    setItem(marketing_activation);
  }, []);

  if (loadingSessionState === ResourceStates.loading) {
    return (
      <div className="bg-gray-200">
        <div className="container mx-auto h-screen sm:max-w-md max-w-full bg-white">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        className="mx-16 flex-none cursor-pointer hover:shadow-md"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <ItemCard item={item} />
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <InfoModal
          item={item}
          onClose={() => {
            setShowModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default ItemPreview;
