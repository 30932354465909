import React from "react";
import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import Button from "../../Atoms/Buttons/Button";
import { Back, Heart, Refresh } from "../../Assets/Icons";
import Logo from "../../Assets/Logo.png";

const Navbar = ({ likedItemCount }) => {
  const { t } = useTranslation();

  return (
    <header className="bg-brand-500">
      <nav className="relative flex flex-nowrap py-4 navbar-expand-lg h-48">
        <div className="container w-full absolute p-8 mx-auto flex flex-row justify-between">
          <div className="flex space-x-8">
            <Button.Round className="bg-brand-500 text-white shadow-none">
              <Back onClick={() => navigate(-1)} />
            </Button.Round>
          </div>

          <div className="flex align-right space-x-8">
            <Button.Round
              className="bg-brand-500 text-white shadow-none"
              onClick={() => navigate("/cravings")}
            >
              <Refresh transform="scale(-1, 1)" />
            </Button.Round>

            <Button.Round
              className="bg-brand-500 text-white shadow-none"
              onClick={() => navigate("/likedItems")}
            >
              {likedItemCount === 0 ? (
                <div />
              ) : (
                <div className="absolute inline-flex h-1/2 w-1/2 text-center">
                  <div className="relative rounded-full p-8 bg-brand-200">
                    <span
                      className="absolute inset-0 text-brand-500 font-medium"
                      style={{ fontSize: "10px" }}
                    >
                      {likedItemCount}
                    </span>
                  </div>
                </div>
              )}
              <Heart />
            </Button.Round>
          </div>
        </div>

        <div className="w-full flex items-center justify-center space-x-8 cursor-pointer">
          <div className="h-24 z-50" onClick={() => navigate("/")}>
            <img src={Logo} alt="SOGUI_Logo" className="h-full" />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
