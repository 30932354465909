import React, { useState } from "react";
import { Router, navigate } from "@reach/router";
import { useLoadingState } from "../Atoms/Loading";
import { ResourceStates } from "../Atoms/Loading/ResourceStates";
import WelcomeScreen from "./LandingPage/WelcomeScreen";
import ItemSwipe from "./ItemSwipe/ItemSwipe";
import LikedItems from "./LikedItems/LikedItems";
import Navbar from "./Layout/Navbar";
import Craving from "./LandingPage/Craving";
import Preferences from "./LandingPage/Preferences";
import { createSwipeSession, fetchSwipeSession } from "./ActivationRequests";
import preferences from "./LandingPage/Preferences";
import Loading from "./Loading";
import ItemPreview from "./ItemSwipe/ItemPreview";
import FrequentlyAskedQuestions from "./FAQs/FrequentlyAskedQuestions";
import useInnerHeight from "../Util/useInnerHeight";
import NotFound from "./NotFound";
import SharedItems from "./LikedItems/SharedItems";

const App = ({ children, likedItemCount }) => {
  const innerHeight = useInnerHeight();
  return (
    <div className="bg-gray-200" path="/*">
      <div
        style={{ "--inner-page-height": `${innerHeight}px` }}
        className="min-h-inner-screen container flex flex-col justify-between mx-auto sm:max-w-md max-w-full bg-white"
      >
        <div className="sticky top-0 z-20">
          <Navbar likedItemCount={likedItemCount} />
        </div>
        <div className="h-full flex flex-1 flex-col justify-center px-16">
          {children}
        </div>
      </div>
    </div>
  );
};
const mapSwipeSessionResponse = (response) => ({
  maxPrice: response.max_price,
  minPrice: response.min_price,
  craving: response.user_craving,
  dietaries: response.user_dietaries,
  distance: response.distance,
  swipeSessionActivations: response.swipe_session_activations,
  uuid: response.uuid,
  hasMoreInSearch: response.has_more_in_search,
});

const EMPTY_SESSION = { empty: true, swipeSessionActivations: [] };

const getLikedItems = (swipeSessionActivations) =>
  swipeSessionActivations.filter(
    (activation) =>
      activation.engagements[activation.engagements.length - 1] === "liked"
  );

const getTotalEngagements = (swipeSessionActivations) =>
  swipeSessionActivations.filter(
    (activation) => activation.engagements.length > 0
  ).length;

const PageNavigation = () => {
  const [session, setSession] = useState(EMPTY_SESSION);
  const [lastLiked, setLastLiked] = useState({});
  const [sessionUuid, setSessionUuid] = useState(1111);

  const loadingSessionState = useLoadingState(async () => {
    const { swipe_session } = await fetchSwipeSession();
    setSession(
      swipe_session ? mapSwipeSessionResponse(swipe_session) : EMPTY_SESSION
    );
  }, []);

  const createNewSession = async (session) => {
    const { swipe_session } = await createSwipeSession(session);
    setSession(mapSwipeSessionResponse(swipe_session));
    setSessionUuid(swipe_session.uuid);
  };

  const onActivationEngagement = (uuid, engagement) => {
    const { swipeSessionActivations } = session;
    const index = swipeSessionActivations.findIndex((a) => a.uuid == uuid);
    swipeSessionActivations[index].engagements.push(engagement);
    if (engagement === "liked") setLastLiked(swipeSessionActivations[index]);
    setSession({ ...session, swipeSessionActivations });
  };

  const updateSession = (newSession) => {
    setSession({ ...session, ...newSession });
  };

  if (loadingSessionState === ResourceStates.loading) {
    return (
      <div className="bg-gray-200">
        <div className="container mx-auto h-screen sm:max-w-md max-w-full bg-white">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Router>
        <WelcomeScreen path="/" swipeSession={session} />

        <App
          path="/"
          likedItemCount={getLikedItems(session.swipeSessionActivations).length}
        >
          <NotFound default />

          <Craving
            path="cravings"
            onCravingsUpdated={(craving) => {
              updateSession({ craving });
              navigate("preferences");
            }}
          />

          <Preferences
            path="preferences"
            isLoading={false}
            onPreferencesUpdated={async (preferences) => {
              await createNewSession({ ...session, ...preferences });
              navigate("swipe");
            }}
          />

          <ItemSwipe
            path="swipe"
            swipeSessionActivations={session.swipeSessionActivations}
            onActivationEngagement={onActivationEngagement}
            lastLiked={lastLiked}
            hasMoreInSearch={session.hasMoreInSearch}
            sessionUuid={sessionUuid}
            updateSession={updateSession}
            likedItems={getLikedItems(session.swipeSessionActivations)}
            totalEngagements={getTotalEngagements(
              session.swipeSessionActivations
            )}
          />

          <LikedItems
            path="likedItems"
            likedItems={getLikedItems(session.swipeSessionActivations)}
            sessionUuid={sessionUuid}
          />

          <FrequentlyAskedQuestions path="faq" />

          <ItemPreview path="activations/:uuid" />

          <SharedItems path="sharedItems/:uuid" getLikedItems={getLikedItems} />
        </App>
      </Router>
    </div>
  );
};

export default PageNavigation;
