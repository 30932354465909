import React from "react";
import { useTranslation } from "react-i18next";
import { HappySogui, Heart, InfoCircle } from "../../Assets/Icons";
import Button from "../../Atoms/Buttons/Button";

const ItemCard = ({ item, recentlyLiked }) => {
  const { t } = useTranslation();

  return (
    <div className>
      <div className="container relative mx-auto rounded-lg shadow-lg overflow-hidden antialiased">
        <div className="aspect-w-7 aspect-h-10">
          <img
            src={item.hero_image}
            alt="Food"
            className="w-full object-cover"
          />
        </div>
        {recentlyLiked && (
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="animate-bounce bg-white rounded-full">
              <HappySogui width={128} height={128} fill="#66F343" />
            </div>
          </div>
        )}
        <div className="absolute bottom-0 w-full">
          <div className="text-left bg-gradient-to-b from-transparent to-black min-h-1/4">
            <div className="flex flex-row justify-between text-white px-16 py-16">
              <div className="w-full">
                <div className="flex flex-row justify-between">
                  <p className="text-lg font-bold">{item.item_name}</p>
                  <p className="text-lg font-bold">
                    {t("itemSwipe.price", {
                      price: parseFloat(item.item_tax_inclusive_price).toFixed(
                        2
                      ),
                    })}
                  </p>
                </div>

                <div className="flex flex-row justify-between">
                  <p className="text-md">{item.outlet_name}</p>
                  <Button.Round className="text-brand-500">
                    <InfoCircle />
                  </Button.Round>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
