import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../Layout/Navbar";
import Questions from "./Questions";

const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation();
  const questions = Object.keys(t("faqPage.faqs", { returnObjects: true }));

  return (
    <div>
      <div className="flex flex-col p-16 space-y-32">
        <h1 className="text-center text-2xl">{t("faqPage.title")}</h1>
        {questions.map((q) => (
          <Questions
            question={t(`faqPage.faqs.${q}.question`)}
            answer={t(`faqPage.faqs.${q}.answer`)}
          />
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
