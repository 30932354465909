import React from "react";
import { useTranslation } from "react-i18next";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";

const SliderWithTooltip = Slider.createSliderWithTooltip(Slider);

const DistanceSliderBar = ({ distance, setDistance }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full px-16 pb-16">
        <SliderWithTooltip
          min={1}
          max={50}
          step={1}
          value={distance}
          onChange={setDistance}
          trackStyle={{ backgroundColor: "#2996FB" }}
          railStyle={{ backgroundColor: "#D1D5DB" }}
          handleStyle={[{ borderColor: "#2996FB" }]}
        >
          <div className="pt-16 flex flex-row text-xs text-gray-600">
            <span>{t("preferences.radiusSelected", { km: distance })}</span>
          </div>
        </SliderWithTooltip>
      </div>
    </div>
  );
};

export default DistanceSliderBar;
