import React, { useState } from "react";
import { useParams } from "@reach/router";
import { useTranslation } from "react-i18next";
import { useLoadingState } from "../../Atoms/Loading";
import { ResourceStates } from "../../Atoms/Loading/ResourceStates";
import Modal from "../../Atoms/Modal";
import InfoModal from "../ItemSwipe/InfoModal";
import { fetchSwipeSession } from "../ActivationRequests";
import Loading from "../Loading";
import LikedItemCard from "./LikedItemCard";

const SharedItems = ({ getLikedItems }) => {
  const { t } = useTranslation();
  const params = useParams();

  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const loadingSessionState = useLoadingState(async () => {
    const { swipe_session } = await fetchSwipeSession(params.uuid);
    setItems(getLikedItems(swipe_session.swipe_session_activations));
  }, []);

  if (loadingSessionState === ResourceStates.loading) {
    return (
      <div className="bg-gray-200">
        <div className="container mx-auto h-screen sm:max-w-md max-w-full bg-white">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="page-content">
        <div className=" pt-16 text-center text-md font-bold text-brand-500">
          {t("likedItems.sharedItems")}
        </div>
        <div className="w-1/2 mx-auto border-t-2 pt-16" />

        <div className="pb-16 gap-8 grid grid-cols-2 ">
          {items.map((item, key) => (
            <div
              className="cursor-pointer hover:shadow-md w-full h-full"
              onClick={() => {
                setShowModal(true);
                setSelectedItem(item.marketing_activation);
              }}
            >
              <LikedItemCard key={key} item={item.marketing_activation} />
            </div>
          ))}
        </div>

        <Modal showModal={showModal} setShowModal={setShowModal}>
          <InfoModal
            item={selectedItem}
            onClose={() => {
              setShowModal(false);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default SharedItems;
