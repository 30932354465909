import React from "react";
import classNames from "classnames";

const Button = ({ children, className, ...props }) => {
  return (
    <button
      type="button"
      className={classNames(
        className,
        "inline-block w-full focus:outline-none focus:shadow-outline px-4 py-4 text-white font-bold hover:opacity-75 rounded-md bg-brand-500 shadow-md transition duration-500 ease-in-out hover:text-white transform hover:-translate-y-1 hover:scale-105"
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export const RoundButton = ({ children, className, active, ...props }) => {
  return (
    <button
      type="button"
      className={classNames(
        className,
        "focus:outline-none focus:shadow-outline px-6 py-3  hover:opacity-75 rounded-full shadow-md transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110",
        {
          "px-6 py-3 rounded-full bg-brand-500 border-2 border-brand-500 outline-none shadow-outline text-white scale-110": active,
          "text-black": !active,
        }
      )}
      {...props}
    >
      {children}
    </button>
  );
};

Button.Round = RoundButton;
export default Button;
