import {
  getJsonRequest,
  patchJsonRequest,
  postJsonRequest,
} from "../Util/ApiRequest";
const posBossHost = process.env.REACT_APP_POSBOSS_HOST;

export const saveActivationEngagements = async (
  swipeSessionActivationUuid,
  activationEngagement
) => {
  await postJsonRequest({
    url: `${posBossHost}/marketing_api/swipe_session_activations/${swipeSessionActivationUuid}/activation_engagements`,
    body: activationEngagement,
  });
};

export const fetchSwipeSession = async () => {
  const { body } = await getJsonRequest({
    url: `${posBossHost}/marketing_api/swipe_session`,
  });
  return body;
};

export const createSwipeSession = async (session) => {
  const { body } = await postJsonRequest({
    url: `${posBossHost}/marketing_api/swipe_session`,
    body: {
      user_craving: session.craving,
      user_dietaries: session.dietaries,
      min_price: session.minPrice,
      max_price: session.maxPrice,
      distance: session.distance,
      latitude: session.latitude,
      longitude: session.longitude,
    },
  });
  return body;
};

export const extendSwipeSession = async (sessionUuid) => {
  const { body } = await patchJsonRequest({
    url: `${posBossHost}/marketing_api/swipe_session/extend_session`,
  });
  return body;
};

export const fetchSwipeSessionActivation = async (sessionId) => {
  const { body: items } = await getJsonRequest({
    url: `${posBossHost}/marketing_api/swipe_session_activations/${sessionId}`,
  });
  return items;
};

export const fetchMarketingActivation = async (uuid) => {
  const { body: item } = await getJsonRequest({
    url: `${posBossHost}/marketing_api/marketing_activations/${uuid}`,
  });
  return item;
};
