import React, { Fragment } from "react";

const LikedItemCard = ({ item }) => {
  return (
    <Fragment>
      <div className="relative rounded-lg shadow-lg overflow-hidden antialiased">
        <div className="aspect-h-10 aspect-w-7">
          <img
            src={item.hero_image}
            className="object-cover h-full w-full"
            alt="Food"
          />
        </div>
        <div className="absolute bottom-0 w-full">
          <div className="text-left bg-gradient-to-b from-transparent to-black min-h-1/4">
            <div className="flex flex-row justify-between text-white p-8">
              <div>
                <p className="text-md font-bold"> {item.item_name}</p>
                <p className="text-sm">{item.outlet_name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LikedItemCard;
