import { useState, useEffect } from "react";
/*
 * This is hack around the fact the following does not work correclty on
 * mobile safari
 * in layout.css
 * height: 100vh
 *
 * Because of the screen content constantly changes due to safaris, interface,
 * the value returned by `100vh` is not accuarte.
 *
 * The value returned by window.innerHeight is accurate.
 * So we pull that and pass it directly to the style attribute
 *
 * https://stackoverflow.com/a/37113430 talks about why
 *
 * It also has to handle resizing that happens when the viewportHeight changes
 * or orientationchanges
 */
const useInnerHeight = () => {
  const [innerHeight, setInnerHeight] = useState();
  useEffect(() => {
    const updateInnerHeight = () => {
      setInnerHeight(window.innerHeight);
    };
    updateInnerHeight();
    window.addEventListener("resize", updateInnerHeight);
    window.addEventListener("orientationchange", updateInnerHeight);
    return () => {
      window.removeEventListener("resize", updateInnerHeight);
      window.removeEventListener("orientationchange", updateInnerHeight);
    };
  }, []);
  return innerHeight;
};

export default useInnerHeight;
