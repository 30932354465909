import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Atoms/Buttons/Button";
import { cravings } from "../../Atoms/DataDefinitions";

const Craving = ({ onCravingsUpdated }) => {
  const { t } = useTranslation();
  const onSelectCraving = async (craving) => {
    onCravingsUpdated(craving);
  };

  return (
    <div>
      <div className="py-32">
        <div className="flex flex-col justify-between text-center space-y-16">
          <div className="text-md font-bold text-gray-700">
            {t("foodSelect.welcome")} <br />
            {t("foodSelect.whatDoYouFeelLike")}
          </div>
          <div />
          <div className="justify-items-center grid grid-cols-3 grid-rows-3 gap-4">
            {cravings.map(({ name, icon }, key) => (
              <div
                key={key}
                className="text-sm text-gray-600 px-16 pb-16 justify-items-center"
              >
                <Button.Round
                  className="p-8 bg-gray-300 text-black hover:bg-brand-500 hover:text-white"
                  onClick={() => onSelectCraving(name)}
                >
                  {icon}
                </Button.Round>
                <p className="pt-12">{t(`cravings.${name}`)}</p>
              </div>
            ))}
          </div>
          <div className="w-full text-center py-4">
            <Button className="w-full" onClick={() => onSelectCraving("")}>
              {t("foodSelect.idk")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Craving;
