import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import AddressLookUp from "../../Atoms/AddressLookUp";
import Button from "../../Atoms/Buttons/Button";
import { dietariesBig } from "../../Atoms/DataDefinitions";
import { ResourceStates } from "../../Atoms/Loading/ResourceStates";
import { useSubmitButton } from "../../Atoms/useSubmitButton";
import CostSliderBar from "./CostSliderBar";
import DistanceSliderBar from "./DistanceSliderBar";

const Preferences = ({ onPreferencesUpdated }) => {
  const { t } = useTranslation();
  const [dietary, setDietary] = useState([]);
  const [cost, setCost] = useState([0, 100]);
  const [distance, setDistance] = useState(5);
  const [coordinates, setCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [manualAddressInput, setManualAddressInput] = useState(false);
  const [submissionState, trackSubmit] = useSubmitButton();
  const [hasCurrentPosition, setHasCurrentPosition] = useState(
    ResourceStates.unloaded
  );

  const handleAddDiet = (newDiet) => {
    if (dietary.includes(newDiet) === false) {
      setDietary([...dietary, newDiet]);
    } else {
      const temp = dietary.filter((d) => d !== newDiet);
      setDietary(temp);
    }
  };

  const handleSubmit = async () => {
    if (cost[0] === 0 && cost[1] === 0) {
      return toast.error(t("preferences.costError"));
    } else if (coordinates.latitude === 0 && coordinates.longitude === 0) {
      return toast.error(t("preferences.locationNeeded"));
    }

    await onPreferencesUpdated({
      dietaries: dietary,
      minPrice: cost[0],
      maxPrice: cost[1],
      distance: distance,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
    });
  };

  const getLocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setHasCurrentPosition(ResourceStates.loaded);
            resolve(position);
          },
          () => {
            reject();
          }
        );
      });
      setCoordinates({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    } catch (e) {
      setHasCurrentPosition("Error");
    }
  };

  return (
    <div className="py-32">
      <div className="flex flex-col justify-between text-center px-16 space-y-32">
        {manualAddressInput ? (
          <div className="space-y-8">
            <div className="text-md font-bold text-gray-700">
              {t("preferences.setAddress")}
            </div>
            <AddressLookUp
              name="address"
              placeholder={t("preferences.address_placeholder")}
              setCoordinates={setCoordinates}
              withCoordinates
            />

            <div
              className="cursor-pointer text-xs text-brand-500"
              onClick={() => setManualAddressInput(false)}
            >
              {t("preferences.useDeviceLocation")}
            </div>
          </div>
        ) : (
          <div className="space-y-8">
            <div className="text-md font-bold text-gray-700">
              {t("preferences.location")}
            </div>

            <div className="text-xs text-gray-600 space-y-8">
              <span>{t("preferences.locationNeeded")}</span>
              <span>{t("preferences.locationNeededConsent")}</span>
            </div>

            {submissionState === ResourceStates.loaded &&
            hasCurrentPosition === ResourceStates.unloaded ? (
              <Button onClick={trackSubmit(getLocation)}>
                {t("preferences.shareMyLocation")}
              </Button>
            ) : (
              <div onClick={trackSubmit(getLocation)}>
                {submissionState === ResourceStates.loading &&
                  hasCurrentPosition === ResourceStates.unloaded &&
                  t("preferences.gettingYourLocation")}
                {submissionState === ResourceStates.loading &&
                  hasCurrentPosition === ResourceStates.loaded &&
                  t("preferences.findingYourItems")}
                {submissionState === ResourceStates.loaded &&
                  hasCurrentPosition === ResourceStates.loaded &&
                  t("preferences.locationFound")}
                {submissionState === ResourceStates.loaded &&
                  hasCurrentPosition === "Error" &&
                  t("preferences.geoLocationError")}
              </div>
            )}

            <div
              className="cursor-pointer text-xs text-brand-500"
              onClick={() => setManualAddressInput(true)}
            >
              {t("preferences.useAddressLookup")}
            </div>
          </div>
        )}

        <div>
          <div className="text-md font-bold text-gray-700">
            {t("preferences.distance")}
          </div>
          <DistanceSliderBar distance={distance} setDistance={setDistance} />
        </div>

        <div>
          <div className="text-md font-bold text-gray-700">
            {t("preferences.cost")}
          </div>
          <CostSliderBar cost={cost} setCost={setCost} />
          <ToastContainer
            limit={1}
            position="top-center"
            autoClose={3500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>

        <div className="text-md font-bold text-gray-700">
          {t("preferences.dietary")}
        </div>

        <div className="">
          <div className="grid grid-cols-3 gap-y-24 gap-x-12">
            {dietariesBig.map(({ name, icon }, key) => (
              <div
                key={key}
                className="text-sm text-center space-y-4 text-gray-600 px-8"
              >
                <Button.Round
                  className={classNames("hover:text-white hover:bg-brand-500", {
                    "bg-gray-300 text-gray-600": !dietary.includes(name),
                    "text-white bg-brand-500 ": dietary.includes(name),
                  })}
                  onClick={() => handleAddDiet(name)}
                  active={dietary.includes(name)}
                >
                  {icon}
                </Button.Round>
                <p className="">{t(`dietaries.${name}`)}</p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <Button onClick={trackSubmit(handleSubmit)}>
            {t("buttons.discoverDelicious")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
