import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "@reach/router";
import { Facebook, Twitter, Instagram } from "../../Assets/Icons";
import WelcomeScreenHeroImageV3 from "../../Assets/WelcomeScreenHeroImageV3.png";
import SoguiLogoBlackAndPinkWithSoftLaunch from "../../Assets/SoguiLogoBlackAndPinkWithSoftLaunch.png";

const WelcomeScreen = ({ swipeSession }) => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col justify-between text-center bg-gradient-to-b from-brand-200 via-white to-white">
      <div className="max-w-screen-sm mx-auto px-16 space-y-48 py-8 sm:py-16 h-full flex flex-col items-center justify-evenly">
        <div className="space-y-24 flex-col pt-48 text-gray-700">
          <img
            src={SoguiLogoBlackAndPinkWithSoftLaunch}
            alt="SOGUI_Logo"
            className="object-cover"
          />
          <h3 className="text-xl"> {t("landing.subHeading")} </h3>
        </div>

        <img
          className="mx-auto"
          alt="Pictures of Food and sogui actions"
          src={WelcomeScreenHeroImageV3}
        />

        <div className="w-full mx-auto">
          <Link
            to="/cravings"
            className="mx-auto py-8 hover:cursor-pointer md:max-w-sm block text-white font-bold text-lg bg-brand-500 rounded-lg drop-shadow-lg"
          >
            {t("buttons.discoverDelicious")}
          </Link>
        </div>
      </div>
      <div className="bg-brand-200 py-16 space-y-24">
        <div className="space-y-16">
          <div className="flex justify-center space-x-8">
            <div>
              <a href="https://posbosshq.com/sogui" target="_blank">
                {t("landing.soguiForBusiness")}
              </a>
            </div>
            <div> |</div>
            <div>
              <Link to="/faq"> {t("landing.faq")} </Link>
            </div>
            <div> |</div>
            <div>
              <a href="https://www.posbosshq.com/privacy-policy">
                {t("landing.privacyPolicy")}
              </a>
            </div>
          </div>
          <div className="flex space-x-8 justify-center">
            <a href="https://facebook.com/Posbosshq" target="_blank">
              <Facebook />
            </a>
            <a href="https://twitter.com/posbosshq" target="_blank">
              <Twitter />
            </a>
            <a href="https://www.instagram.com/posbosshq" target="_blank">
              <Instagram />
            </a>
          </div>
        </div>
        <div>
          <Trans i18nKey={"landing.poweredByPosboss"} />{" "}
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
