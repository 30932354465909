import {
  CafeStyle,
  DairyFree,
  Drinks,
  FODMAP,
  GlutenFree,
  Halal,
  HungryBeast,
  Kosher,
  LightAndFresh,
  NoodlesAndRice,
  NutFree,
  QuickAndGreasy,
  StandIn,
  Sweet,
  TreatingMyself,
  Vegan,
  Vegetarian,
  WarmAndComforting,
} from "../Assets/Icons";
import React from "react";

const bigSize = 48;
export const dietariesBig = [
  { name: "vegan", icon: <Vegan height={bigSize} width={bigSize} /> },
  { name: "vegetarian", icon: <Vegetarian height={bigSize} width={bigSize} /> },
  { name: "dairy_free", icon: <DairyFree height={bigSize} width={bigSize} /> },
  {
    name: "gluten_free",
    icon: <GlutenFree height={bigSize} width={bigSize} />,
  },
  { name: "fodmap", icon: <FODMAP height={bigSize} width={bigSize} /> },
  { name: "nut_free", icon: <NutFree height={bigSize} width={bigSize} /> },
  { name: "halal", icon: <Halal height={bigSize} width={bigSize} /> },
  { name: "kosher", icon: <Kosher height={bigSize} width={bigSize} /> },
];

export const cravings = [
  { name: "warm_and_comforting", icon: <WarmAndComforting fill="black" /> },
  { name: "cafe_style", icon: <CafeStyle fill="black" /> },
  { name: "light_and_fresh", icon: <LightAndFresh fill="black" /> },
  { name: "quick_and_greasy", icon: <QuickAndGreasy fill="black" /> },
  { name: "hungry_beast", icon: <HungryBeast fill="black" /> },
  { name: "treating_myself", icon: <TreatingMyself fill="black" /> },
  { name: "noodles_and_rice", icon: <NoodlesAndRice fill="black" /> },
  { name: "sweet", icon: <Sweet fill="black" /> },
  { name: "drinks", icon: <Drinks fill="black" /> },
];

export const costFilter = [
  { group: "$", value: 20, display: "Cheap ass", icon: <StandIn /> },
  { group: "$$", value: 35, display: "🤷🏻‍", icon: <StandIn /> },
  { group: "$$$", value: 50, display: "Baller", icon: <StandIn /> },
];
