import { ResourceStates } from "./Loading/ResourceStates";
import { useState } from "react";

export const useSubmitButton = ({ errorTimeout, ...opts } = {}) => {
  const [submissionState, setSubmissionState] = useState(ResourceStates.loaded);
  const trackSubmit = (onSubmit) => async (data) => {
    try {
      setSubmissionState(ResourceStates.loading);
      const response = await onSubmit(data);
      setSubmissionState(ResourceStates.loaded);
      return response;
    } catch (error) {
      console.error(error);
      setSubmissionState(ResourceStates.error);
      setTimeout(
        () => setSubmissionState(ResourceStates.loaded),
        errorTimeout || 1000
      );
    }
  };

  return [submissionState, trackSubmit];
};
