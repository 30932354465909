import React from "react";
import { useTranslation } from "react-i18next";
import { WithLoadingComponent } from "./LoadingState";

export const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center flex items-center content-center justify-center py-16">
      <div className="space-y-16 text-center my-auto">
        <p className="text-lg">{t("loading.page")}</p>
      </div>
    </div>
  );
};

const ErrorLoadingPage = () => {
  const { t } = useTranslation();

  return <div>Error</div>;
};
const LoadingPage = WithLoadingComponent(Loading, ErrorLoadingPage);
export default LoadingPage;
