import React from "react";
import { useTranslation } from "react-i18next";
import "rc-slider/assets/index.css";
import Slider, { Range } from "rc-slider";

const RangeWithTooltip = Slider.createSliderWithTooltip(Range);

const CostSliderBar = ({ cost, setCost }) => {
  const { t } = useTranslation();

  const getValue = (val) => {
    const defaultValue = [5, 30];
    if (val === 0) return defaultValue;
    else return [val[0], val[1]];
  };

  return (
    <div>
      <div className="w-full px-16 pb-16">
        <RangeWithTooltip
          step={5}
          allowCross={false}
          value={getValue(cost)}
          onChange={setCost}
          trackStyle={[{ backgroundColor: "#2996FB" }]}
          railStyle={{ backgroundColor: "#D1D5DB" }}
          handleStyle={[{ borderColor: "#2996FB" }]}
        >
          <div className="pt-16 flex flex-row justify-between text-xs text-gray-600">
            <div>{t("preferences.costMin", { min: cost[0] })}</div>
            <div>{t("preferences.costMax", { max: cost[1] })}</div>
          </div>
        </RangeWithTooltip>
      </div>
    </div>
  );
};

export default CostSliderBar;
