import React from "react";
import { split } from "lodash";
import { useTranslation } from "react-i18next";
import { Heart, Location, Menu, Phone, Share } from "../../Assets/Icons";
import HappySogui from "../../Assets/HappySogui.jpg";

const ActionButton = ({ children, className, ...props }) => {
  return (
    <div
      className={`rounded-md py-8 px-8 flex-col flex-1 flex items-center space-y-4 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

const ActionBar = ({ venue, onLike }) => {
  const { t } = useTranslation();

  const shareItem = async (title, text, url) => {
    try {
      await navigator.share({
        title: title,
        text: text,
        url: url,
      });
    } catch (e) {
      window.location.reload();
      console.log("Sharing failed", e);
    }
  };

  return (
    <div className=" border-t border-gray-200 py-8">
      <div className="flex flex-row space-x-8 text-center items-center text-white ">
        <ActionButton
          className="text-brand-500 border-brand-500 border"
          onClick={() => window.open(`${venue.outlet_menu_url}`)}
        >
          <Menu />
          {t("itemSwipe.viewMenu")}
        </ActionButton>

        {navigator.share && (
          <ActionButton
            className="bg-brand-200 text-brand-500"
            onClick={() => {
              shareItem(
                t("itemSwipe.shareText", {
                  itemName: venue.item_name,
                  venueName: venue.outlet_name,
                }),
                t("itemSwipe.shareTitle", { itemName: venue.item_name }),
                `/activations/${venue.uuid}`
              );
            }}
          >
            <Share />
            {t("itemSwipe.share")}
          </ActionButton>
        )}

        {onLike && (
          <ActionButton className="text-white bg-brand-500" onClick={onLike}>
            <Heart />
            {t("itemSwipe.like")}
          </ActionButton>
        )}
      </div>
    </div>
  );
};

const VenueInfo = ({ venue }) => {
  return (
    <div className="flex space-x-16">
      <div className="object-cover flex-shrink-0">
        <div className="flex flex-col">
          <img
            src={venue.outlet_logo || HappySogui}
            className="rounded-full w-96 h-96 shadow-md"
            alt="Logo"
          />
        </div>
      </div>
      <div className="flex-row flex-grow">
        <div className="flex flex-col w-full justify-between pt-8 pr-16 space-y-4">
          <p className="text-md font-bold text-gray-700">{venue.outlet_name}</p>

          <div
            className="cursor-pointer flex flex-row space-x-8 items-center "
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${venue.outlet_latitude},${venue.outlet_longitude}`
              )
            }
          >
            <div className="text-brand-500">
              <Location />
            </div>
            <p className="text-gray-600 text-xs">{venue.outlet_address}</p>
          </div>

          {venue.outlet_phone && (
            <div
              className="cursor-pointer flex flex-row space-x-8 items-center "
              onClick={() => window.open(`tel:${venue.outlet_phone}`)}
            >
              <div className="text-brand-500">
                <Phone />
              </div>
              <p className="text-gray-600 text-xs">{venue.outlet_phone}</p>
            </div>
          )}
          <div className="flex flex-row items-baseline text-xs text-gray-600 pt-4 space-x-8">
            <div className="space-y-2">
              {split(venue.outlet_opening_hours, "\n").map((line) => (
                <div> {line} </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemDescription = ({ item, onLike }) => {
  const { t } = useTranslation();

  return (
    <div className="h-full container bg-white outline-none focus:outline-none ">
      <div className="mx-auto bg-white space-y-16">
        <div className="container mx-auto  aspect-w-7 aspect-h-10 antialiased ">
          <img
            src={item.hero_image}
            className="object-cover w-full"
            alt="Food"
          />
        </div>

        <div
          className="bg-white px-16 py-12 rounded-t sticky  justify-between flex text-2xl"
          style={{ bottom: "84px" }}
        >
          <p>{item.item_name}</p>
          <p>
            {t("itemSwipe.price", {
              price: parseFloat(item.item_tax_inclusive_price).toFixed(2),
            })}
          </p>
        </div>
        <div className="px-16 space-y-16">
          <p>{item.item_marketing_blurb}</p>
          <div className="border-t border-gray-400" />
          <p className="text-xs">{t("itemSwipe.foundAt")}</p>
          <VenueInfo venue={item} />
        </div>
        <div className="sticky inset-x-0 mt-12 bottom-0 px-8 bg-white ">
          <ActionBar venue={item} onLike={onLike} />
        </div>
      </div>
    </div>
  );
};

export default ItemDescription;
