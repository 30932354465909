import React from "react";
import "./styles/index.css";
import "./i18n";
import PageNavigation from "./Pages/PageNavigation";

const App = () => {
  return (
    <div className="App">
      <PageNavigation />
    </div>
  );
};

export default App;
