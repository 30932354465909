import React from "react";
import ItemDescription from "./ItemDescription";
import { Close } from "../../Assets/Icons";
import useInnerHeight from "../../Util/useInnerHeight";

const InfoModal = ({ item, onClose, onLike }) => {
  const innerHeight = useInnerHeight;
  return (
    <div className="h-inner-height flex fixed inset-0 z-20 max-w-full sm:max-w-md mx-auto  outline-none focus:outline-none">
      <div className="mt-48 z-50 absolute top-8 left-8" onClick={onClose}>
        <div className="rounded-full inline-block text-white p-2 bg-brand-500 shadow">
          <Close />
        </div>
      </div>
      <div className="mt-48 z-30 overflow-y-none flex-1">
        <div className="h-full overflow-y-auto rounded-t-2xl">
          <ItemDescription item={item} onLike={onLike} />
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-10 bg-black" />
    </div>
  );
};

export default InfoModal;
