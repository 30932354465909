import React from "react";

const Questions = ({ question, answer }) => {
  return (
    <div>
      <div className="font-medium">{question}</div>
      <div>{answer}</div>
    </div>
  );
};

export default Questions;
