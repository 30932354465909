import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { isEmpty } from "lodash";
import { HappySogui, SadSogui } from "../../Assets/Icons";
import Modal from "../../Atoms/Modal";
import { saveActivationEngagements } from "../ActivationRequests";
import ItemCard from "./ItemCard";
import InfoModal from "./InfoModal";
import EndOfQueue from "./EndOfQueue";
import ExhaustedSearch from "./ExhaustedSearch";

const ItemSwipe = ({
  swipeSessionActivations,
  onActivationEngagement,
  hasMoreInSearch,
  sessionUuid,
  updateSession,
  likedItems,
  totalEngagements,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [recentlyLiked, setRecentlyLiked] = useState(false);

  const sessionActivation = swipeSessionActivations.find((item) =>
    isEmpty(item.engagements)
  );

  const handleEngagementFor = (interaction) => async ({ uuid }) => {
    onActivationEngagement(uuid, interaction);
    const activationEngagement = {
      interaction: interaction,
    };
    await saveActivationEngagements(
      sessionActivation.uuid,
      activationEngagement
    );
  };

  const handleDislike = handleEngagementFor("disliked");
  const handleLike = handleEngagementFor("liked");

  const onLike = async () => {
    setRecentlyLiked(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 1500);
    });

    await setRecentlyLiked(false);
    handleLike(sessionActivation);
  };

  if (!sessionActivation && hasMoreInSearch) {
    return (
      <div>
        <EndOfQueue
          sessionUuid={sessionUuid}
          updateSession={updateSession}
          likedItems={likedItems}
          totalEngagements={totalEngagements}
        />
      </div>
    );
  } else if (!sessionActivation && !hasMoreInSearch) {
    return (
      <div>
        <ExhaustedSearch likedItems={likedItems} />
      </div>
    );
  }

  return (
    <div className="py-32">
      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="flex flex-col ">
        <div
          className="mx-16 flex-none cursor-pointer hover:shadow-md"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <ItemCard
            item={sessionActivation.marketing_activation}
            recentlyLiked={recentlyLiked}
          />
        </div>

        <div className="h-1/16 flex flex-row pt-16 px-32 justify-center space-x-64">
          <div
            className="p-4 shadow-md text-xs items-center rounded-full text-brand-500"
            onClick={() => handleDislike(sessionActivation)}
          >
            <SadSogui width="72px" height="72px" />
          </div>
          <div
            className="p-4 shadow-md text-xs items-center rounded-full text-brand-green"
            onClick={onLike}
          >
            <HappySogui width="72px" height="72px" />
          </div>
        </div>

        <Modal showModal={showModal} setShowModal={setShowModal}>
          <InfoModal
            item={sessionActivation.marketing_activation}
            onClose={() => {
              setShowModal(false);
            }}
            onLike={() => {
              setShowModal(false);
              onLike();
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ItemSwipe;
