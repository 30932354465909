import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "./Layout/Navbar";
import { HappySogui } from "../Assets/Icons";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Navbar />
      <div className="page-content p-16 flex m-auto">
        <div className="space-y-16 flex flex-col m-auto justify-center items-center">
          <div>
            <HappySogui fill="#F4406B" />
          </div>

          <div>{t("loading")}</div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
