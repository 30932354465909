import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LikedItemCard from "./LikedItemCard";
import InfoModal from "../ItemSwipe/InfoModal";
import Modal from "../../Atoms/Modal";
import Button from "../../Atoms/Buttons/Button";

const LikedItems = ({ likedItems, sessionUuid }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  // Currently hiding the button to share a session as the API that's getting used to pull the data is incorrect.
  const showShareButton = false;

  const shareSession = async (sessionUuid) => {
    try {
      await navigator.share({
        title: t("likedItems.shareItemsTitle"),
        text: t("likedItems.shareTheseGoodies"),
        url: `/sharedItems/${sessionUuid}`,
      });
    } catch (e) {
      window.location.reload();
      console.log("Sharing failed", e);
    }
  };

  const items = Array.from(
    new Set(likedItems.map((item) => item.marketing_activation_id))
  ).map((id) => {
    return likedItems.find((item) => item.marketing_activation_id === id);
  });

  return (
    <div>
      <div className="page-content">
        <div className=" pt-16 text-center text-md font-bold text-brand-500">
          {t("likedItems.yourLikedItems")}
        </div>
        <div className="w-1/2 mx-auto border-t-2 pt-16" />

        <div className="pb-16 gap-8 grid grid-cols-2 ">
          {items.map((item, key) => (
            <div
              className="cursor-pointer hover:shadow-md w-full h-full"
              onClick={() => {
                setShowModal(true);
                setSelectedItem(item.marketing_activation);
              }}
            >
              <LikedItemCard key={key} item={item.marketing_activation} />
            </div>
          ))}
        </div>

        {showShareButton && navigator.share && items && (
          <div className="py-16">
            <Button onClick={() => shareSession(sessionUuid)}>
              {t("likedItems.shareList")}
            </Button>
          </div>
        )}

        <Modal showModal={showModal} setShowModal={setShowModal}>
          <InfoModal
            item={selectedItem}
            onClose={() => {
              setShowModal(false);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default LikedItems;
