import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";
import InfoModal from "./InfoModal";
import Modal from "../../Atoms/Modal";

const LikedItemsSuggestions = ({ likedItems }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const items = Array.from(
    new Set(likedItems.map((item) => item.marketing_activation_id))
  ).map((id) => {
    return likedItems.find((item) => item.marketing_activation_id === id);
  });

  return (
    <div className="space-y-16">
      <div>
        <h3 className="text-lg">
          {t("itemSwipe.alreadyLikedNItems", { count: items.length })}{" "}
        </h3>
        <p>{t("itemSwipe.tryThemOut")}</p>
      </div>

      <div className="flex justify-between space-x-8">
        {items.slice(0, 2).map((item, key) => (
          <div className="flex-1">
            <div className="cursor-pointer hover:shadow-md">
              <div className="relative rounded-lg shadow-lg overflow-hidden antialiased ">
                <div className="aspect-h-10 aspect-w-7">
                  <img
                    src={item.marketing_activation.hero_image}
                    className="object-cover h-full w-full"
                    alt="Food"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                </div>
              </div>
            </div>

            <Modal showModal={showModal} setShowModal={setShowModal}>
              <InfoModal
                item={item.marketing_activation}
                onClose={() => {
                  setShowModal(false);
                }}
              />
            </Modal>
          </div>
        ))}
        {items.length > 2 && (
          <Link
            to="../likedItems"
            className="flex-1 block flex items-center justify-center text-brand-500 shadow-lg rounded-lg bg-brand-200"
          >
            {t("itemSwipe.plusNMore", { count: items.length - 2 })}
          </Link>
        )}
      </div>
    </div>
  );
};

export default LikedItemsSuggestions;
