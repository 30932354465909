import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import classNames from "classnames";

const AddressLookUp = ({
  className,
  onChange,
  setCoordinates,
  withCoordinates = true,
  ...props
}) => {
  const {
    suggestions: { data, status },
    value: address,
    setValue: setAddress,
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 200 });

  const handleChange = (e) => {
    onChange && onChange(e);
    if (withCoordinates) {
      setAddress("latitude", null);
      setAddress("longitude", null);
    }
    setAddress(e.target.value);
    clearSuggestions();
  };

  const handleSelect = ({ description }) => () => {
    setAddress(description, false);
    if (withCoordinates) {
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setCoordinates({ latitude: lat, longitude: lng });
        });
    }
    clearSuggestions();
  };

  return (
    <div>
      <input
        className={classNames(
          "bg-white text-sm focus:outline-none focus:ring border-2 rounded border-primary-100 py-8 px-16 block w-full appearance-none leading-normal",
          className
        )}
        autoComplete="off"
        onChange={handleChange}
        value={address}
        {...props}
      />
      <ul
        className={classNames(
          "shadow rounded-b border-primary-100 absolute bg-white z-10 overflow-x-auto md:w-1/3",
          { "border-2": data.length !== 0 }
        )}
      >
        {status === "OK" &&
          data.map((suggestion, index) => {
            const {
              id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;
            return (
              <li
                key={index}
                className="p-4 px-12 text-xs text-gray-700 text-left cursor-pointer hover:bg-brand-200"
                onClick={handleSelect(suggestion)}
              >
                <span className="font-semibold">{main_text} </span>
                <span className="text-xs">{secondary_text}</span>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default AddressLookUp;
