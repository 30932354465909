import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Atoms/Buttons/Button";
import { extendSwipeSession } from "../ActivationRequests";
import LikedItemsSuggestions from "./LikedItemsSuggestions";

const EndOfQueue = ({
  sessionUuid,
  updateSession,
  likedItems,
  totalEngagements,
}) => {
  const { t } = useTranslation();

  const handleGetMore = async () => {
    const { swipe_session } = await extendSwipeSession(sessionUuid);
    updateSession({
      swipeSessionActivations: swipe_session.swipe_session_activations,
      hasMoreInSearch: swipe_session.has_more_in_search,
    });
  };

  return (
    <div>
      <div className="m-auto py-12">
        <div className="space-y-48 text-center">
          {likedItems.length > 1 ? (
            <LikedItemsSuggestions likedItems={likedItems} />
          ) : (
            <div>
              <h3 className="text-lg">
                {t("itemSwipe.youveSwipedThroughNItems", {
                  count: totalEngagements,
                })}
              </h3>
            </div>
          )}

          <div className="space-y-16">
            <div>
              <div className="text-sm">
                {likedItems.length === 0
                  ? t("itemSwipe.hitRefreshAbove")
                  : t("itemSwipe.stillNotReadyToDecide")}
              </div>
            </div>
            <Button onClick={() => handleGetMore()}>
              {t("itemSwipe.showMeMore")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndOfQueue;
